<template>
  <div>
    <localDataGetter
        :application="stepType"
        :ipcanStepTemplates="ipcanStepTemplates"
        :result="result"
        :stepStoredValue="stepStoredValue"
        :stepType="stepType"
        v-on="$listeners"
        @local-data-changed="updateTemplate">
      <template v-slot:default="data" v-on="$listeners">
        <v-row>
          <v-col v-if="stepType === 'RADIUS_ACCESS' || stepType === 'RADIUS_ACCOUNTING'" cols="6">
            <!-- <fieldsRows
              v-if="indexedTemplates"
              :templateContent="indexedTemplates"
              @field-value-changed="updateParameterValue(...arguments)"
            ></fieldsRows>-->
            <buildFieldsRows
                v-if="indexedTemplates"
                v-model="parameters"
                :templateContent="indexedTemplates"
                languageIndex="ipcanStep"/>
          </v-col>

          <v-col>
            <ipcanConnectionContainer
                v-if="data.localData != null"
                :localData="data.localData"
                :result="result"
                :stepID="stepID"
                :stepStoredValue="stepStoredValue"
                :stepType="stepType"
                class="radius-step-ipcan-connection-container"
                v-on="$listeners"/>
          </v-col>
        </v-row>
        <div v-if="data.localData != null">
          <!-- <v-divider></v-divider> -->
          <!-- radius step -->
          <radiusStep
              v-if="stepType === 'RADIUS_ACCESS' || stepType === 'RADIUS_ACCOUNTING'"
              :localData="data.localData"
              :radiusDynamicPars="dynamicPars.IPCAN"
              :result="result"
              :stepStoredValue="stepStoredValue"
              :stepType="stepType"
              :templatesData="data.localData.templates"
              v-on="$listeners"/>
          <genericRadiusStep
              v-else-if="stepType === 'RADIUS'"
              :localData="data.localData"
              :radiusDynamicPars="dynamicPars.IPCAN"
              :result="result"
              :stepStoredValue="stepStoredValue"
              :stepType="stepType"
              :templates="templates"
              v-on="$listeners"/>
          <diameterStep
              v-else
              :radiusDynamicPars="dynamicPars.IPCAN"
              :result="result"
              :stepID="stepID"
              :stepStoredValue="stepStoredValue"
              :stepType="stepType"
              :templatesData="data.localData.templates"
              v-on="$listeners"/>
        </div>
      </template>
    </localDataGetter>
  </div>
</template>

<script>
//import fieldsRows from "@/commonComponents/fieldsRows.vue";
import buildFieldsRows from '@/commonComponents/buildFieldsRows.vue';
import ipcanConnectionContainer from '@/components/legacy/ipcanConnectionContainer.vue';
import genericRadiusStep from '@/components/legacy/genericRadiusStep.vue';
import radiusStep from '@/components/legacy/radiusStep.vue';
import diameterStep from '@/components/legacy/diameterStep.vue';
import localDataGetter from '@/components/legacy/localDataGetter.vue';
import {initializeDynamicParams} from '@/js/testHelpers.js';

export default {
  components: {
    // fieldsRows,
    ipcanConnectionContainer,
    genericRadiusStep,
    radiusStep,
    diameterStep,
    localDataGetter,
    buildFieldsRows,
  },
  props: [
    'stepStoredValue',
    'stepType',
    'groupedStepDependentInfo',
    'result',
    'stepID',
    'ipcanStepTemplates',
    'additionalStepsInfo',
  ],
  data() {
    return {
      indexedTemplates: null,

      dynamicPars: {},
      evalAliases: {},

      templates: null,
    };
  },
  watch: {
    stepType(newVal) {
      console.log('changed', newVal);
      this.calculateIndexedTemplates();
    },
  },
  created() {
    this.calculateIndexedTemplates();

    // this.setConnectionsValues();
    let evalInfo = initializeDynamicParams(
        this.result.additional.additionalEvalParams,
    );

    this.dynamicPars = evalInfo.dynamicPars;
    this.evalAliases = evalInfo.evalAliases;

    // $.ajax({
    //   type: "POST",
    //   url: "serve.php?f=testing&f2=configureAndRunTests",
    //   data: {
    //     function: "getIpcanFields",
    //     subscriber: subscriber,
    //     parameters: parameters,
    //     stepType: stepType,
    //     singlePacket: singlePacket,
    //     requestType: "ajax"
    //   },
    //   dataType: "json",
    //   success: loadIpcanCallBack($additionalFields, fieldsObject),

    //   error: function(xhr, textStatus) {
    //     var args = [
    //       fieldsObject,
    //       stepId,
    //       subscriber,
    //       parameters,
    //       stepType,
    //       $step
    //     ];
    //     /* args["fieldsObject"]=fieldsObject;
    //                     args["stepId"]=stepId;
    //                     args["subscriber"]=subscriber;
    //                     args["parameters"]=parameters;
    //                     args["stepType"]=stepType;
    //                     args["step"]=$step;*/
    //     checkLoginRequired(
    //       xhr,
    //       function(args) {
    //         // sendIpcanParametersRenderRequest(fieldsObject,stepId,subscriber,parameters,stepType,$step);
    //         sendIpcanParametersRenderRequest.apply(this, args);
    //       },
    //       args
    //     );
    //   }
    // });
  },
  methods: {
    calculateIndexedTemplates() {
      if (
          typeof this.groupedStepDependentInfo.indexedTemplates != 'undefined' &&
          typeof this.groupedStepDependentInfo.indexedTemplates[
          this.stepID + this.stepType
              ] != 'undefined'
      ) {
        this.indexedTemplates = this.groupedStepDependentInfo.indexedTemplates[
        this.stepID + this.stepType
            ];
        this.indexedTemplates.disableAllInputs = this.result.disableAllInputs;
        // loadIpcanStep(
        //   $additionalFields,
        //   fieldsObject,
        //   indexedTemplates[stepId + stepType]
        // );
      } else {
        let subscriber = '';
        if (
            typeof this.result.valueLine.n_subscriberId != 'undefined' &&
            typeof this.result.valueLine.n_subscriberId != 'undefined'
        ) {
          subscriber = this.result.valueLine.n_subscriberId;

          let options = {
            function: 'getIpcanFields',
            subscriber: subscriber,
            parameters: this.parameters,
            stepType: this.stepType,
            singlePacket: true,
            requestType: 'ajax',
          };
          let caller = this;
          this.frameworkAxiosRequest({
            method: 'post',
            url: 'serve.php?f=testing&f2=configureAndRunTests',
            data: options,
          })
              .then(function (response) {
                //console.log(response);
                //   resolve(response.data.result.json.operatorList);
                response.data.result.disableAllInputs =
                    caller.result.disableAllInputs;
                caller.indexedTemplates = response.data.result;
                let localAdditionalStepsInfo = {...caller.additionalStepsInfo};
                caller.$set(
                    localAdditionalStepsInfo,
                    'calledStations',
                    response.data.result.additionalStepsInfo.calledStations,
                );
                caller.$emit(
                    'update-additional-steps-info',
                    localAdditionalStepsInfo,
                );
              })
              .catch(function (error) {
                //handle error
                console.log(error);
              });
        }
      }
    },
    updateTemplate(value) {
      if (!value.selectedFields.packet) return;
      const packet = JSON.parse(value.templates.packet);
      if (packet['RADIUS']) this.templates = packet['RADIUS'];
    },
  },
  computed: {
    parameters: {
      get() {
        let parameters = {};
        if (this.stepStoredValue && this.stepStoredValue.parameters) {
          parameters = this.stepStoredValue.parameters;
        }
        return parameters;
      },
      set(newVal) {
        // console.log(newVal);
        let localStoredStep = {...this.stepStoredValue};
        this.$set(localStoredStep, 'parameters', newVal);
        this.$emit('stored-value-changed', localStoredStep);
      },
    },
  },
};
</script>

<style scoped>
/deep/ .radius-step-ipcan-connection-container > div > div:first-child {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  row-gap: 10px;
}

/deep/ .radius-step-ipcan-connection-container > div > div > div:first-child {
  width: 1000px;
}

/deep/ .radius-step-ipcan-connection-container > div > div > div:last-child {
  width: 500px;
  margin-bottom: 15px;
}

/deep/ .radius-step-ipcan-connection-container > div > div > div .v-text-field__details {
  display: none;
}

/deep/ .radius-step-ipcan-connection-container .openAddField {
  margin-left: 6px;
}
</style>