<template>
  <div class="generic-radius-step">
    <RadiusPacket
        title="Request Packet"
        v-model="req"
        :templateRows="reqConfiguration.templateRows"/>
    <headline-box headline="Response Evaluation" padded>
      <div class="response-evaluation">
        <div class="input-row">
          <x-select
              v-model="res.expectedResponseCode"
              :items="resConfiguration.radiusCodes"
              required
              item-text="1"
              item-value="0"
              label="Expected Response"
              validate-immediately/>
          <span>else</span>
          <x-select
              v-model="res.onWrongResponse.result"
              :items="resConfiguration.resultItems"
              required
              label="Result"
              validate-immediately/>
          <x-select
              v-model="res.onWrongResponse.action"
              :items="resConfiguration.actionItems"
              required
              item-text="1"
              item-value="0"
              label="Action"
              validate-immediately/>
          <x-text-field
              v-model="res.onWrongResponse.info"
              label="Info"
              max-length="100"
              @input="$emit('input', $event)"
          />
        </div>

        <hr/>
         <template-list-editor
            v-model="res.eval.add"
            :columns="resConfiguration.evalColumns"
            :deactivated-template-rows="res.eval.deactivated"
            :disabled-template-columns="resConfiguration.disabledTemplateColumns"
            :template-rows="resConfiguration.templateRows"
            row-name="Evaluation"
            validate-immediately
            @deactivated-update="res.eval.deactivated = $event"/>
        <hr/>
        <div class="input-row">
          <span>On Timeout</span>
          <x-select
              v-model="res.onTimeout.result"
              :items="resConfiguration.resultItems"
              required
              label="Result"
              validate-immediately/>
          <x-select
              v-model="res.onTimeout.action"
              :items="resConfiguration.actionItems"
              required
              item-text="1"
              item-value="0"
              label="Action"
              validate-immediately/>
          <HelpButton id="onTimeout_block" class="ml-n2"></HelpButton>
        </div>
      </div>
    </headline-box>
  </div>
</template>

<script>
import XTextField from '@/components/basic/XTextField';
import HeadlineBox from '@/components/basic/HeadlineBox';
import TemplateListEditor from '@/components/extended/TemplateListEditor';
import XSelect from '@/components/basic/XSelect';
import options from '@/cfg/options.json';
import {getOperators} from '@/js/testStepsHelpers/commonTestFunctions';
import RadiusPacket from '@/components/specific/RadiusPacket';
import HelpButton from "../basic/HelpButton.vue";
import { tunnelTagObject } from "@/js/helper.js";

export default {
  components: {
    HelpButton,
    XTextField,
    RadiusPacket,
    XSelect,
    TemplateListEditor,
    HeadlineBox,
  },
  props: [
    'templates',
    'stepStoredValue',
    'stepType',
    'result',
    'radiusDynamicPars',
    'ipcanStepTemplates',
    'localData',
  ],
  data() {
    return {
      req: {
        deactivated: [],
        add: {},
      },
      reqConfiguration: {
        attributeColumns: [
          {
            type: 'select',
            value: 'attribute',
            items: [],
            itemValue: 'id',
            itemText: this.getAttributeText,
            label: 'Attribute',
            required: true,
          },
          {
            type: this.getAttributeValueInputType,
            value: 'content',
            items: this.getAttributeValueInputItems,
            itemValue: 'id',
            itemText: (row) => {
              return `${row.name} (${row.id})`;
            },
            label: 'Value',
            required: true,
          },
        ],
        disabledTemplateColumns: ['attribute'],
        templateRows: {},
        showTemplate: false,
      },
      res: {
        eval: {
          deactivated: [],
          add: [],
        },
        expectedResponseCode: 0,
        onWrongResponse: {
          result: 'error',
          action: 'stopTest',
        },
        onTimeout: {
          result: 'runtime_error',
          retry: '0',
          action: 'stopTest',
        },
      },
      resConfiguration: {
        evalColumns: [
          {
            label: 'Type',
            type: 'select',
            value: 'attribute',
            items: [],
            itemValue: 'id',
            itemText: this.getAttributeText,
            inputType: 'int',
            outputType: 'string',
            required: true,
          },
          {
            label: 'Tag',
            type: 'select',
            value: 'tag',
            items: this.tunnelTagObject({ id:"any",name:"Any"}),
            itemValue: 'id',
            itemText: 'name',
            //inputType: 'int',
            outputType: 'string',
            //required: true,
            condition: (row) => {
              if (row.attribute) {
                const attribute = this.reqConfiguration.attributeColumns[0].items.find(x => x.id === parseInt(row.attribute));
                if (!attribute) return false;
                const type = attribute.type;
                if(type=='Tunnel-Integer' ||type=='Tunnel-String' ||type=='Tunnel-Password') return true;
              }
              return false;
            },
          },
          {
            label: 'Operator',
            type: 'select',
            value: 'operator',
            items: (row) => {
              if (row.attribute) {
                const rowAttribute = parseInt(row.attribute);
                const attribute = this.resConfiguration.evalColumns[0].items.find(x => x.id === rowAttribute);
                if (attribute) {
                  return getOperators(attribute.type, options.operators);
                }
              }
              return [];
            },
            required: true,
          },
          {
            label: 'Value',
            type: this.getAttributeValueInputType,
            value: 'content',
            items: this.getAttributeValueInputItems,
            itemValue: 'id',
            itemText: (row) => {
              return `${row.name} (${row.id})`;
            },
            condition: (row) => {
              return !options.hiddenArray.includes(row.operator);
            },
            required: true,
          },
          {
            type: 'span',
            value: 'else',
            required: true,
          },
          {
            label: 'Result',
            type: 'select',
            value: 'result',
            items: options.ldapResult,
            itemValue: '0',
            itemText: '1',
            default: 'error',
            required: true,
          },
          {
            label: 'Action',
            type: 'select',
            value: 'action',
            items: options.evalAction,
            itemValue: '0',
            itemText: '1',
            default: 'stopTest',
            required: true,
          },
          {
            label: 'Info',
            type: 'text',
            value: 'info',
            required: false,
          },
        ],
        templateRows: [],
        disabledTemplateColumns: ['attribute'],
        radiusCodes: options.radiusCode,
        resultItems: [
          {
            value: 'ok',
            text: 'OK',
          },
          {
            value: 'warning',
            text: 'Result Warning',
          },
          {
            value: 'error',
            text: 'Result Error',
          },
          {
            value: 'runtime_warning',
            text: 'Processing Warning'
          },
          {
            value:'runtime_error',
            text:'Processing Error'
          },
        ],
        actionItems: options.evalAction,
      },
    };
  },
  mounted() {
    this.getAttributes();
  },
  watch: {
    templates: {
      immediate: true,
      handler(value) {
        if (!value) return;

        if (Object.keys(this.reqConfiguration.templateRows).length) {
          this.req.deactivated = [];
        }
        if (value.req) {
          this.reqConfiguration.templateRows = value.req.attributes;
        } else {
          this.reqConfiguration.templateRows = {};
        }

        if (this.resConfiguration.templateRows.length) this.res.deactivated = [];
        if (value.res) {
          const resTemplateRows = this.deepCopy(value.res.eval);
          for (let i = 0; i < resTemplateRows.length; i++) {
            resTemplateRows[i].attribute = parseInt(resTemplateRows[i].attribute);
          }
          this.resConfiguration.templateRows = resTemplateRows;
        } else {
          this.resConfiguration.templateRows = [];
        }

        if (!this.result.valueLine['n_id']) {
          const localStoredStep = this.deepCopy(this.stepStoredValue);
          localStoredStep.req = this.req;
          localStoredStep.res = this.res;
          this.$emit('stored-value-changed', localStoredStep);
        }
      },
    },
    'stepStoredValue.req': {
      immediate: true,
      deep: true,
      handler(value) {
        if (value && !this.deepEquals(value, this.req)) this.req = value;
      },
    },
    'stepStoredValue.res': {
      immediate: true,
      deep: true,
      handler(value) {
        if (value && !this.deepEquals(value, this.res)) this.res = value;
      },
    },
    req: {
      deep: true,
      handler(value) {
        const localStoredStep = this.deepCopy(this.stepStoredValue);
        localStoredStep.req = value;
        this.$emit('stored-value-changed', localStoredStep);
      },
    },
    res: {
      deep: true,
      handler(value) {
        const localStoredStep = this.deepCopy(this.stepStoredValue);
        localStoredStep.res = value;
        this.$emit('stored-value-changed', localStoredStep);
      },
    },
  },
  computed: {
    selectedAttribute() {
      return this.attributes.find(x => x.id === this.value.attribute);
    },
    visibleRequestTemplateRows() {
      if (this.reqConfiguration.showTemplate) {
        return this.reqConfiguration.templateRows;
      } else {
        const visibleTemplateRows = {};
        for (const [key, value] of Object.entries(this.reqConfiguration.templateRows)) {
          const rows = value.filter(x => this.parseBoolean(x.inputRequired) || this.req.deactivated.includes(x.id));
          if (rows.length) visibleTemplateRows[key] = rows;
        }
        return visibleTemplateRows;
      }
    },
  },
  methods: {
    tunnelTagObject:tunnelTagObject,
    getAttributes() {
      this.services.dictionary.getRadiusAttributes((attributes) => {
        this.reqConfiguration.attributeColumns[0].items = attributes;
        this.resConfiguration.evalColumns[0].items = attributes;
      });
    },
    getAttributeText(row) {
      const id = row.id % 100000;
      const vendor = parseInt(row.id / 100000);
      let fullId = id;
      if (vendor) fullId = `${vendor}-${id}`;
      return `${row.name} (${fullId})`;
    },
    getAttributeValueInputType(row) {
      if (!row) return 'text';
      const attribute = this.reqConfiguration.attributeColumns[0].items.find(x => x.id === parseInt(row.attribute));
      if (!attribute) return 'text';
      const type = attribute.type;
      switch (type) {
        case 'integer':
          return 'int';
        case 'enum':
          return 'select';
        default:
          return 'text';
      }
    },
    getAttributeValueInputItems(row) {
      if (!row) return [];
      const attribute = this.reqConfiguration.attributeColumns[0].items.find(x => x.id === parseInt(row.attribute));
      if (!attribute) return [];
      return attribute.enum;
    },
  },
};
</script>

<style scoped>
.generic-radius-step {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.show-template-switch {
  justify-content: flex-end;
}

.response-evaluation {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.input-row {
  display: flex;
  gap: 10px;
}

.input-row > span {
  margin-top: 8px;
}
</style>