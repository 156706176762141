<template>
  <div>
    <list-editor :columns="dataColumns"
                 :focused-element-index="focusedElementIndex"
                 :max="max"
                 :min="min"
                 :persistent-list-length="persistentListLength"
                 :row-name="rowName"
                 :validate-immediately="validateImmediately"
                 :value="dataValue"
                 @input="emitInput"/>
  </div>
</template>

<script>
import ListEditor from '@/components/basic/ListEditor';

export default {
  name: 'KeyListEditor',
  components: {ListEditor},
  props: {
    columns: Array,
    value: Object,
    keyName: String,
    keyType: String,
    min: [Number, String],
    max: [Number, String],
    persistentListLength: Boolean,
    focusedElementIndex: {
      type: Number,
      default: -1,
    },
    validateImmediately: Boolean,
    rowName: String,
  },
  data() {
    return {
      dataValue: [],
      dataColumns: [],
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        const rows = [];
        if (value) {
          for (const [k, v] of Object.entries(value)) {
            for (const vRow of v) {
              const row = this.deepCopy(vRow);
              let typedK = k;
              switch (this.keyType) {
                case('int'):
                  typedK = parseInt(k);
                  break;
                case('float'):
                  typedK = parseFloat(k);
                  break;
                default:
                  break;
              }
              this.$set(row, this.keyName, typedK);
              rows.push(row);
            }
          }
        }
        if (!this.deepEquals(rows, this.dataValue)) {
          this.$nextTick(() => this.dataValue = rows);
        }
      },
    },
    columns: {
      immediate: true,
      deep: true,
      handler(value) {
        const columns = this.deepCopy(value);
        columns.find(x => x.value === this.keyName).required = true;
        this.dataColumns = columns;
      },
    },
  },
  methods: {
    emitInput(value) {
      const rows = {};
      for (const valueItem of value) {
        let key = valueItem[this.keyName];
        delete valueItem[this.keyName];
        key = key ? key.toString() : '';
        if (key) {
          if (!rows[key]) rows[key] = [];
          rows[key].push(valueItem);
        } else {
          return;
        }
      }
      this.$emit('input', rows);
    },
  },
};
</script>

<style scoped>

</style>