<template>
  <div>
    <v-card class="mb-6 mt-3" elevation="0" outlined>
      <v-toolbar color="primary" dark flat height="50">
        <v-toolbar-title class="subtitle-2">{{
          language.componentLabel.labelRequestPacket
        }}</v-toolbar-title>
        <v-spacer></v-spacer>{{ language.componentLabel.labelShowTemplate }}:
        <div v-for="(info, index) in config" :key="index" class="ml-2">
          <v-switch
            hide-details
            v-model="info.requestEnabled"
            :label="info.name"
            :color="info.color"
            :disabled="disabled"
          ></v-switch>
        </div>
      </v-toolbar>
      <v-container>
        <testDiameterRequestAttr
          :stepStoredValue="stepStoredValue"
          :result="result"
          :mergedTemplate="mergedTemplate"
          :stepType="stepType"
          :stepID="stepID"
          :radiusDynamicPars="radiusDynamicPars"
          :config="config"
          :templatesData="templatesData"
          @input="updateRequest"
        ></testDiameterRequestAttr>
      </v-container>
    </v-card>
    <v-card class="mb-6 mt-3" elevation="0" outlined>
      <v-toolbar color="primary" dark flat height="50">
        <v-toolbar-title class="subtitle-2">{{
          language.componentLabel.labelRPE
        }}</v-toolbar-title>
        <!-- <v-spacer></v-spacer>Show Templates:
        <div class="ml-2">
          <v-switch
            hide-details
            v-model="responseDetails"
            :label="config.t_templates.name"
            :color="config.t_templates.color"
          ></v-switch>
        </div> -->
      </v-toolbar>
      <v-container>
        <diameterResponseAttr
          :attrsArray="result['additional']['diameterAttrs']"
          :value="responseObject"
          :application="stepType"
          :linePrefix="stepType + '_' + stepID + '_response'"
          source="t_testcases"
          :radiusDynamicPars="radiusDynamicPars"
          @input="updateRes(...arguments)"
          :result="result"
        ></diameterResponseAttr>

        <v-divider class="mt-3 mb-6"></v-divider>
        <onTimeOutNoResult
          onEvent="On Timeout"
          v-model="onTimeout"
          :result="result"
        ></onTimeOutNoResult>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import {
  getConfiguredValue,
  //getStepValue,
  addEditedRadiusDiameterValues,
  addAttributes,
} from "@/js/testHelpers.js";
import testDiameterRequestAttr from "@/components/legacy/testDiameterRequestAttr.vue";

import diameterResponseAttr from "@/components/legacy/diameterResponseAttr.vue";
import onTimeOutNoResult from "@/components/legacy/onTimeOutNoResult.vue";
import {
  getIndexedTemplate,
  buildDiameterRequestTemplate,
} from "@/js/testHelpers.js";
//import associatedOption from "@/commonComponents/associatedOption.vue";
export default {
  props: [
    "templatesData",
    "stepStoredValue",
    "stepType",
    "stepID",
    
    "result",

    "radiusDynamicPars",
  ],
  components: {
    testDiameterRequestAttr,
    onTimeOutNoResult,
    diameterResponseAttr,
    // associatedOption,
  },
  data() {
    return {
      // requestDetails: false,
      responseDetails: true,
      config: {
        t_localClients: {
          color: "red",
          name: "Client",
          requestEnabled: false,
          type: "client",
        },
        t_remoteServers: {
          color: "blue",
          name: "Server",
          requestEnabled: false,
          type: "server",
        },
        t_templates: {
          color: "green",
          name: "Packet",
          requestEnabled: false,
          type: "packet",
        },
      },
    };
  },

  computed: {
    disabled() {
      let disabled = false;
      if (
        typeof this.result != "undefined" &&
        typeof this.result.disableAllInputs != "undefined" &&
        this.result.disableAllInputs == true
      ) {
        disabled = true;
      }
      return disabled;
    },
    onTimeout: {
      get() {
        var onTimeout = {};
        if (
          typeof this.stepStoredValue != "undefined" &&
          typeof this.stepStoredValue["res"] != "undefined"
        ) {
          if (typeof this.stepStoredValue.res.onTimeout != "undefined") {
            onTimeout = this.stepStoredValue.res.onTimeout;
          }
        }
        return onTimeout;
      },
      set(newVal) {
        let localStoredStep = { ...this.stepStoredValue };

        if (localStoredStep == null || typeof localStoredStep == "undefined") {
          localStoredStep = {};
        }
        if (
          localStoredStep == null ||
          typeof localStoredStep == "undefined" ||
          typeof localStoredStep.res == "undefined"
        ) {
          this.$set(localStoredStep, "res", {});
        }
        this.$set(localStoredStep.res, "onTimeout", newVal);
        //console.log("value changed ", localStoredStep);
        this.$emit("stored-value-changed", localStoredStep);
      },
    },
    combinedRequest() {
      let ipcanStep = this.stepType;
      var template = {};
      var configuredValue = getConfiguredValue(this.stepType, ipcanStep);
      //template = $.extend(true, {}, configuredValue);
      //console.log(configuredValue);
      template = JSON.parse(JSON.stringify(configuredValue));
      if (this.config.t_templates.requestEnabled) {
        template = buildDiameterRequestTemplate(
          template,

          ipcanStep,
          this.templatesData,
          this.config["t_templates"]
        );
      }
      if (this.config.t_remoteServers.requestEnabled) {
        template = buildDiameterRequestTemplate(
          template,

          ipcanStep,
          this.templatesData,
          this.config["t_remoteServers"]
        );
      }
      if (this.config.t_localClients.requestEnabled) {
        console.log("client Enabled");
        template = buildDiameterRequestTemplate(
          template,

          ipcanStep,
          this.templatesData,
          this.config["t_localClients"]
        );
      }
      //console.log(template);
      //  this.combinedTemplate = template;
      return template.req;
    },
    combinedResponse() {
      let ipcanStep = this.stepType;
      var template = {};
      var configuredValue = getConfiguredValue(this.stepType, ipcanStep);
      //template = $.extend(true, {}, configuredValue);
      //console.log(configuredValue);
      template = JSON.parse(JSON.stringify(configuredValue));
      template = this.buildDiameterResponseTemplate(
        template,
        "t_templates",
        ipcanStep,
        this.templatesData
      );
      template = this.buildDiameterResponseTemplate(
        template,
        "t_remoteServers",
        ipcanStep,
        this.templatesData
      );
      template = this.buildDiameterResponseTemplate(
        template,
        "t_localClients",
        ipcanStep,
        this.templatesData
      );
      //console.log(template);
      //  this.combinedTemplate = template;
      return template.res;
    },
    mergedTemplate() {
      let ipcanStep = this.stepType;

      let combinedTemplate = {};
      combinedTemplate = getConfiguredValue(this.stepType, ipcanStep);
      //console.log(combinedTemplate);
      //if (this.requestDetails) {
      combinedTemplate.req = this.combinedRequest;
      // }
      if (this.responseDetails) {
        combinedTemplate.res = this.combinedResponse;
      }
      let mergedTemplate = addEditedRadiusDiameterValues(
        combinedTemplate,

        ipcanStep,
        // fieldsObject,
        this.stepStoredValue,
        this.stepType
      );
      return mergedTemplate;
    },
    // coloredStoredStepValue() {
    //   let coloredStoredStepValue = { ...this.stepStoredValue };
    //   if (
    //     typeof this.stepStoredValue.res != "undefined" &&
    //     typeof this.stepStoredValue.res.eval != "undefined"
    //   ) {
    //     for (let [protocol, valueObject] of Object.entries(
    //       this.stepStoredValue.res.eval
    //     )) {
    //       for (let [attribute, valueArray] of Object.entries(valueObject)) {
    //         //console.log(valueArray);
    //         valueArray.forEach((line, index) => {
    //           //console.log(line);
    //           let color = "";
    //           if (typeof this.config[line.source] != "undefined") {
    //             color = this.config[line.source].color;
    //           }
    //           this.$set(
    //             coloredStoredStepValue.res.eval[protocol][attribute][index],
    //             "color",
    //             color
    //           );
    //         });
    //       }
    //     }
    //   }
    //   return coloredStoredStepValue;
    // },
    expectedResponse() {
      let expectedResponse;

      if (this.stepType == "RADIUS_ACCESS") {
        if (
          typeof this.stepStoredValue.res != "undefined" &&
          typeof this.stepStoredValue.res.expectedResponse != "undefined"
        ) {
          expectedResponse = this.stepStoredValue.res.expectedResponse;
        } else {
          expectedResponse = "ACCESS_ACCEPT";
          this.updateExpected(expectedResponse);
        }
      } else if (this.stepType == "RADIUS_ACCOUNTING") {
        expectedResponse = "ACCOUNTING_RESPONSE";
      }
      return expectedResponse;
    },
    responseObject() {
      let responseObject = {};
      if (
        typeof this.mergedTemplate.res != "undefined" &&
        typeof this.mergedTemplate.res.eval != "undefined"
      ) {
        responseObject = this.mergedTemplate.res.eval;
      }
      return responseObject;
    },
  },
  methods: {
    updateRequest(request) {
      let localStoredStep = { ...this.stepStoredValue };
      this.$set(localStoredStep, "req", request);
      this.$emit("stored-value-changed", localStoredStep);
    },
    updateExpected(expected) {
      let localStoredStep = { ...this.stepStoredValue };
      if (typeof localStoredStep.res == "undefined") {
        this.$set(localStoredStep, "res", {});
      }
      this.$set(localStoredStep.res, "expectedResponse", expected);
      this.$emit("stored-value-changed", localStoredStep);
    },

    updateRes(resValue) {
      let localStoredStep = { ...this.stepStoredValue };
      if (typeof localStoredStep.res.eval == "undefined") {
        this.$set(localStoredStep.res, "eval", {});
      }

      let response = {};
      for (const [key, valueArray] of Object.entries(resValue)) {
        valueArray.forEach((value) => {
          // this.$set(value, "source", "t_testcases");

          if (typeof response[key] != "undefined") {
            response[key].push(value);
          } else {
            response[key] = [value];
          }
          // }
        });
      }
      this.$set(localStoredStep.res, "eval", response);
      this.$emit("stored-value-changed", localStoredStep);
    },

    // buildDiameterRequestTemplate(
    //   template,
    //   objectTemplate,
    //   stepType,
    //   index,
    //   ipcanStep
    // ) {
    //   var config = {
    //     clientValue: { color: "red" },
    //     packetValue: { color: "green" },
    //     serverValue: { color: "blue" },
    //   };
    //   let color = config[index]["color"];

    //   if (objectTemplate["req"]) {
    //     var mergedValue = $.extend(true, {}, objectTemplate["req"]);
    //     // after merging the all with the type merge the values taken from the template into the final object
    //     template["req"] = $.extend(
    //       true,
    //       {},
    //       addAttributes(template["req"], mergedValue, color)
    //     );
    //   }

    //   return template;
    // },

    // buildRadiusResponseTemplate(template, index, ipcanStep, templatesData) {
    //   let config = this.config[index];
    //   let color = this.config[index]["color"];
    //   let objectTemplate = getIndexedTemplate(
    //     config.type,
    //     ipcanStep,
    //     templatesData
    //   );
    //   if (objectTemplate["res"]) {
    //     for (let [resEvalIndex, resEvalValue] of Object.entries(
    //       objectTemplate["res"]["eval"]
    //     )) {
    //       if (typeof template["res"]["eval"][resEvalIndex] == "undefined") {
    //         let foundeval = addAttributes(resEvalIndex, resEvalValue, color);
    //         template["res"]["eval"][resEvalIndex] = foundeval;
    //       } else {
    //         template["res"]["eval"][resEvalIndex] = addAttributes(
    //           template["res"]["eval"][resEvalIndex],
    //           resEvalValue,
    //           color
    //         );
    //       }
    //     }
    //   }
    //   return template;
    // },
    buildDiameterResponseTemplate(template, index, ipcanStep, templatesData) {
      let config = this.config[index];
      let color = this.config[index]["color"];
      let objectTemplate = getIndexedTemplate(
        config.type,
        ipcanStep,
        templatesData
      );
      console.log(objectTemplate);
      if (objectTemplate["res"]) {
        // add common attributes to the response evaluation values

        if (typeof objectTemplate["res"]["eval"] == "undefined") {
          objectTemplate["res"]["eval"] = {};
        }
        var mergedValue = JSON.parse(
          JSON.stringify(objectTemplate["res"]["eval"])
        );
        template["res"]["eval"] = JSON.parse(
          JSON.stringify(
            addAttributes(template["res"]["eval"], mergedValue, color)
          )
        );
      }
      console.log(template);
      return template;
    },
  },
};

//  function buildRadiusDiameterStructure($stepPlace,stepId,ipcanStep,fieldsObject,stepType){
//     var $testCasesGroup=$stepPlace.closest(".testCasesGroup");
//     let template= $testCasesGroup.data( "template" )[stepId][ipcanStep];
//     var requestAttributeId="RequestAttributes"+stepId;
//     var responseAttributeId="ResponseAttributes"+stepId;
//     var $ipcanStep=$stepPlace.find('.ipcanStep[name='+ipcanStep+']');
//     $ipcanStep.find( '.templateTable').empty( );
//     var $table=$ipcanStep.find( '.templateTable');

//     var $requestRow=$("<tr><td colspan=3><div class='RequestAttrs tabs attributegroup' id='"+requestAttributeId+"'></div></td></tr>").appendTo($table);
//     let $requestAttrsContainer =$requestRow.find('.RequestAttrs');

//     if (!checkDiameterProtocol(ipcanStep)){
//       // not for diameter
//       let checkingResult=showHideTabs(template.req);
//       buildRadiusRequestResponseAttributesStructure("Request",stepType,"",$requestAttrsContainer,checkingResult.showInTestConfigured,checkingResult.showExpandable);
//       let $attributesTbody=$requestAttrsContainer.find('.attributesTbody');
//       let $expandableAttributesTbody=$requestAttrsContainer.find('.expandableAttributesTbody');

//       $.each(template.req,function(reqIndex,reqValue){
//         var row="";

//         getRadiusRequestAttrRow(window.dictionarisArray['RADIUS_ACCESS'],reqIndex,reqValue,$attributesTbody,$expandableAttributesTbody);

//         if (Object.keys(template.req).length>0 ){
//           $attributesTbody.closest("table").children(".addingRowTbody").find(".deleteAllAttributes").html(getDeleteAllAttributesButton());
//         }

//       });

//       $table.append("<tr><td colspan=3><div class='ResponseAttrs' id='"+responseAttributeId+"'></div></td></tr>");
//       var $responseAttrsContainer=$table.find('.ResponseAttrs');
//       expandAttrField("Response",stepType,stepId,$responseAttrsContainer,template);
//     }
//     var $responseHolder =$table.find('.responseHolder');
//     $responseHolder.append('<div class="contentSeperator"></div>');
//     $("<div class='onActionContainer'></div>").appendTo($responseHolder );
//     let $onActionContainer = $responseHolder .find(".onActionContainer");
//      var onTimeout={};
//      if (typeof template["res"]!="undefined" && typeof template.res.onTimeout!="undefined"){
//       onTimeout=template.res.onTimeout;
//      }
//     addOnTimeOutNoResult($onActionContainer, "On Timeout", "onTimeoutContainer", onTimeout);

//     if (typeof $testCasesGroup.data( "objectsArray" )[stepId][ipcanStep].packetValue!="undefined" && typeof $testCasesGroup.data( "objectsArray" )[stepId][ipcanStep].packetValue[ipcanStep]!="undefined"&& typeof $testCasesGroup.data( "objectsArray" )[stepId][ipcanStep].packetValue[ipcanStep].parameters!="undefined"){
//       // store value of the release and the packet type
//       var packet= $testCasesGroup.data( "objectsArray" )[stepId][ipcanStep].packetValue[ipcanStep].parameters.packet;
//       var release= $testCasesGroup.data( "objectsArray" )[stepId][ipcanStep].packetValue[ipcanStep].parameters.release;
//       $ipcanStep.find('.releasesselect').val(release);
//       $ipcanStep.find('.treeselector').attr('attrid',packet);

//     }
//     var ipcanStepSelector=$stepPlace.find('.ipcanStep[name='+ipcanStep+']');

//     if (checkSelectPacket(stepType)){
//       var $selectPacket=ipcanStepSelector.find(".selectPacket");
//       var selectPacketVal=$selectPacket.val();
//       var newRequest={};
//       newRequest[selectPacketVal]= template ["req"][selectPacketVal];
//       template ["req"]=newRequest;
//       var newResponse={};
//       newResponse["eval"]={};
//       newResponse["eval"][selectPacketVal]= template ["res"]["eval"][selectPacketVal];
//       template ["res"]=newResponse;
//     }
//     // rendering diameter request

//     if(template.req){
//      /* if(ipcanStep=="DIAMETER"){
//         renderNewDiamerterRequestValues(fieldsObject,$requestAttrsContainer,template);
//       }
//   else if(ipcanStep=="3GPP_GX" ||
//         ipcanStep=="DCCA"||
//         ipcanStep=="3GPP_SY" ||
//         ipcanStep=="SWM_DER"||
//         ipcanStep=="SWM_AAR"||
//         ipcanStep=="SWM_STR"||
//         ipcanStep=="SWX" ||
//         //ipcanStep=="DIAMETER" ||
//         ipcanStep=="3GPP_STA_DER" ||
//         ipcanStep=="3GPP_STA_AAR" ||
//         ipcanStep=="3GPP_STA_STR" ||
//         ipcanStep=="3GPP_SWA_DER" ||
//         ipcanStep=="3GPP_SWA_STR" ||
//         ipcanStep=="3GPP_S6B_AAR" ||
//         ipcanStep=="3GPP_S6B_STR" ||
//         ipcanStep=="3GPP_RX_AAR"  ||
//         ipcanStep=="3GPP_RX_STR"){*/
//         if (checkDiameterProtocol(ipcanStep)){
//         renderDiamerterRequestValues(fieldsObject,$requestAttrsContainer,template);
//       }
//     }
//     // the accordion has to be refreshed after all the content is loaded. otherwise the accordion is not expanded. expanding it before would make no effect.
//     var $testCases=$stepPlace.closest( ".testCases" );
//     var isAccordion = $testCases.hasClass("ui-accordion");
//     if (isAccordion==true){
//       $testCases.accordion( "refresh" );
//     }
//   }
</script>