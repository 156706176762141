<template>
  <div>
    <step_connectionTemplateBlock
      v-if="localData != null"
      :connectionOption="localData.options.connectionId"
      :templateOption="localData.options.templateId"
      :connectionValue="stepStoredValue.connectionId"
      :templateValue="stepStoredValue.templateId"
      @value-changed="updateTemplate"
      :addUrlAttr="{
        subType: stepStoredValue.subType,
        category: stepStoredValue.category,
        stepType: stepType,
        stepID: stepID,
      }"
      :stepStoredValue="stepStoredValue"
      :dialogRef="result.dialogRef"
      @updated-fields="emitUpdateFields"
      v-on="$listeners"
      :result="result"
    ></step_connectionTemplateBlock>
  </div>
</template>
<script>
import step_connectionTemplateBlock from "@/components/legacy/step_connectionTemplateBlock.vue";
export default {
  components: {
    step_connectionTemplateBlock,
  },
  props: ["localData", "stepStoredValue", "result", "stepType","stepID"],
  methods: {
    emitUpdateFields(info) {
      console.log("updated-fields emmited", info);
      for (let [field, line] of Object.entries(info)) {
        this.$set(this.localData.options, field, line.associatedOption);
        this.updateTemplate(field, line.value);
      }

      this.$emit("local-data-changed", this.localData);
    },
    updateTemplate(field, value) {
      console.log("update tempate called", value);
      let localStoredStep = { ...this.stepStoredValue };
      if (localStoredStep == null) {
        localStoredStep = {};
      }
      this.$set(localStoredStep, field, value);
      this.$emit("stored-value-changed", localStoredStep);
    },
  },
};
</script>
